import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
import { req } from '@/api';

interface ThisMonth {
	y: number;
	m: number; //1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}
interface ThisDate extends ThisMonth {
	d: number;
}

let timer: number | undefined = undefined;

export default defineComponent({
	setup(props: any, { root }: any) {
		const { $message, $route, $router } = root;
		const t = root.$t.bind(root);
		const oDate = new Date();
		const y = ref(oDate.getFullYear());
		const m = ref(oDate.getMonth() + 1);
		const d = ref(oDate.getDate());
		//如果存在携带日期参数的路由，则改变状态
		if ($route?.params?.date && typeof $route.params.date === 'string' && $route.params.date.length === 8) {
			const n = {
				y: parseInt($route.params.date.substring(0, 4)),
				m: parseInt($route.params.date.substring(4, 6)),
				d: parseInt($route.params.date.substring(6, 8)),
			};
			if (1990 < n.y && n.y < 2038 && 0 < n.m && n.m <= 12 && 0 < n.d && n.d <= 31) {
				y.value = n.y;
				m.value = n.m;
				d.value = n.d;
			}
		}
		const thisMonth: Ref<ThisMonth> = ref({
			y: y.value,
			m: m.value,
		});
		const thisDate: Ref<ThisDate> = ref({
			y: y.value,
			m: m.value,
			d: d.value,
		});
		const allDate: Ref<any> = ref([]);
		const getAllDate = () => {
			const { y, m } = thisMonth.value;
			const monthFirstDay = new Date(y, m - 1, 1, 0, 0, 0).getDay(); //这个月的第一天是星期几
			const all = [];
			for (let i = 1; i <= 31; i++) {
				all[monthFirstDay + i - 1] = i;
			}
			const res: any = [];
			for (let i = 0; i < all.length; i++) {
				if (!(res as any)[Math.floor(i / 7)]) {
					(res as any)[Math.floor(i / 7)] = [];
				}
				(res as any)[Math.floor(i / 7)][i % 7] = all[i];
			}
			const res1 = res.map((item: any) => {
				if (item.length !== 7) {
					item.length = 7;
				}
				return item;
			});
			allDate.value = res1;
		};
		const dateCount: Ref<any> = ref({});
		const changeDateCount = (oMonth: ThisMonth): void => {
			//发送请求，根据服务器返回的数据动态添加点
			//req();
			dateCount.value = {};
			clearTimeout(timer);
			timer = setTimeout(() => {
				req('BLOG_ARTICLE_CALENDAR', {
					month: (oMonth => ('' + oMonth.y).padStart(4, '0') + ('' + oMonth.m).padStart(2, '0'))(oMonth),
				})
					.then((data: any) => {
						dateCount.value = { ...data };
					})
					.catch(error => {
						dateCount.value = {};
						$message.error('无法获取当前月份的文章数量:' + error);
					});
			}, 500);
		};
		watch(
			() => thisMonth.value.y,
			() => {
				getAllDate();
			}
		);
		watch(
			() => thisMonth.value.m,
			() => {
				getAllDate();
			}
		);
		onMounted(() => {
			getAllDate();
			changeDateCount(thisMonth.value);
		});
		onBeforeUnmount(() => {
			clearTimeout(timer);
		});
		const changeMonth = (value: number | ThisMonth): void => {
			if (typeof value === 'number') {
				const newM = (thisMonth.value.m + value) % 12 || 12;
				const newY = thisMonth.value.y + Math.floor((thisMonth.value.m - 1 + value) / 12);
				thisMonth.value.y = newY;
				thisMonth.value.m = newM;
			} else {
				thisMonth.value.y = value.y;
				thisMonth.value.m = value.m;
			}
			//延迟获取文章信息
			changeDateCount(thisMonth.value);
		};
		const covernDate = (oDate: ThisDate): string => {
			let res = '';
			res += ('' + oDate.y).padStart(4, '0') + ('' + oDate.m).padStart(2, '0') + ('' + oDate.d).padStart(2, '0');
			return res;
		};
		const selectDate = (oDate: ThisDate): void => {
			thisDate.value = oDate;
			//跳转至归档路由里的子路由
			if (dateCount.value[oDate.d]) {
				$router
					.push(`/blog/article/archive/${covernDate(oDate)}`)
					.then(() => {
						//
					})
					.catch(() => {
						//
					});
			}
		};
		return {
			y,
			m,
			d,
			thisMonth,
			thisDate,
			allDate,
			changeMonth,
			selectDate,
			dateCount,
			changeDateCount,
			t,
		};
	},
});
