import {
    defineComponent,
    onBeforeUnmount,
    onMounted,
    ref,
} from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
import { Skeleton, Empty } from '@/components/UI';
import { req } from '@/api';

let isMounted = false;
export default defineComponent({
    components: { Skeleton, Empty },
    setup() {
        const isLoading: Ref<boolean> = ref(true);
        const data: Ref<any[]> = ref([
            {
                onlyid: 'audwqe',
                text: '学习笔记',
            },
            {
                onlyid: 'fgdurty',
                text: '一生有你',
            },
            {
                onlyid: 'mbgfg',
                text: '池塘',
            },
            {
                onlyid: '/.kl;jk',
                text: '花开',
            },
            {
                onlyid: '678fy',
                text: '美文',
            },
            {
                onlyid: 'ouitrt',
                text: 'javascript',
            },
            {
                onlyid: '1sfwe',
                text: 'css',
            },
        ]);
        onMounted(() => {
            isMounted = true;
            req('BLOG_ARTICLE_TAG_ALL', {
                count: 10,
            })
                .then((responseData: any) => {
                    data.value = [...responseData];
                })
                .catch(error => {
                    console.warn(error);
                    data.value = [];
                })
                .finally(() => {
                    isLoading.value = false;
                });
        });
        onBeforeUnmount(() => {
            isMounted = false;
        });
        return { data, isLoading };
    },
});
