




















import { defineComponent, reactive, ref, watch } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
import store from '@/store';

import { generateOnlyid } from '@/public/methods';

//console.log(store?.state);

interface Count {
	blogArticle: number;
	blogRead: number;
	blogCategory: number;
	blogComment: number;
	blogPraise: number;
	blogWord: number;
	blogTag: number;
	phrase: number;
	[propName: string]: number;
}
interface TypeData {
	text: string | (() => string);
	count: string;
}

export default defineComponent({
	setup(props, { root }: any) {
		const t = root.$t.bind(root);
		const count: Ref<Count> = ref({
			blogArticle: -1,
			blogRead: -1,
			blogCategory: -1,
			blogComment: -1,
			blogPraise: -1,
			blogWord: -1,
			blogTag: -1,
			phrase: -1,
		});
		const onlyKey: Ref<string> = ref('');
		watch(
			() => store.state.language,
			() => {
				onlyKey.value = '' + generateOnlyid();
				//console.log('变化了');
			},
			{ immediate: true }
		);
		const data: Ref<any[]> = ref([
			{
				text: () => t('partBlogAccount.blogArticle') || '文章数量',
				count: 'blogArticle',
			},
			{
				text: () => t('partBlogAccount.blogCategory') || '分类数量',
				count: 'blogCategory',
			},
			{
				text: () => t('partBlogAccount.blogTag') || '标签数量',
				count: 'blogTag',
			},
			{
				text: () => t('partBlogAccount.blogRead') || '总阅读量',
				count: 'blogRead',
			},
			{
				text: () => t('partBlogAccount.blogPraise') || '点赞数量',
				count: 'blogPraise',
			},
			{
				text: () => t('partBlogAccount.blogComment') || '评论数量',
				count: 'blogComment',
			},
			{
				text: () => t('partBlogAccount.phrase') || '说说数量',
				count: 'phrase',
			},
			{
				text: () => t('partBlogAccount.blogWord') || '总字数',
				count: 'blogWord',
			},
		]);
		const switchColor = () => {
			function rand(start: number, end: number) {
				return Math.floor(start + (end - start) * Math.random());
			}
			return `rgba(${rand(65, 128)},${rand(65, 128)},${rand(65, 128)},0.8)`;
		};
		watch(
			() => store?.state?.isLoadedFirstScreen,
			value => {
				if (value) {
					watch(
						() => (store?.state?.data?.all as any)?.appInfo?.count,
						value1 => {
							if (value1) {
								//console.log('监听到了变化', value1);
								count.value = { ...count.value, ...value1 };
							}
						},
						{ immediate: true, deep: true }
					);
				}
			},
			{ immediate: true }
		);
		const toNum = (num: number): string => {
			if (num < 0) {
				return t('partBlogAccount.no') || '未统计';
			} else if (num > 100_0000) {
				return '100w+';
			} else if (num > 10_0000) {
				return '10w+';
			} else if (num > 1_0000) {
				return '1w+';
			} else if (num > 999) {
				return '999+';
			} else {
				return '' + num;
			}
		};
		return { data, switchColor, count, toNum };
	},
});
