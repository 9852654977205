

















import { defineComponent, onMounted, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
import { getArea } from '@/api';

import { Skeleton } from '@/components/UI';

export default defineComponent({
	components: { Skeleton },
	setup() {
		const isLoading: Ref<boolean> = ref(true);
		const data: Ref = ref([
			{
				onlyid: '@suibi',
				title: '生活随笔',
				children: [
					{
						onlyid: '@love',
						title: '情话微甜',
					},
					{
						onlyid: '@travel',
						title: '旅行日记',
					},
				],
			},
			{
				onlyid: '@daily',
				title: '个人日记',
			},
			{
				onlyid: '@zhuanzai',
				title: '网络文摘',
			},
			{
				onlyid: '@study',
				title: '学习笔记',
			},
		]);
		onMounted(() => {
			getArea('BLOG_ARTICLE_CATEGORY')
				.then(data1 => (data.value = data1))
				.catch()
				.finally(() => {
					isLoading.value = false;
				});
		});
		return { isLoading, data };
	},
});
