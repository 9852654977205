import { defineComponent, watch, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import PageNodeMain from '@/components/PageNodeMain';
import PageNodeSub from '@/components/PageNodeSub/index.vue';

import Profile from '@/components/Article/Profile';
import Activity from '@/components/Article/Activity';
import SearchLite from '@/components/Article/SearchLite';
import BlogAccount from '@/components/Article/BlogAccount';
import BlogCategory from '@/components/Article/BlogCategory';
import BlogCalendar from '@/components/Article/BlogCalendar';
import BlogTag from '@/components/Article/BlogTag';

import { Empty } from '@/components/UI';

import store from '@/store';

import Card from '@/components/Card';
import pub from '@/public/pub';

interface Anchor {
	onlyid: string;
	text: string;
	tagName: string;
}

export default defineComponent({
	components: {
		PageNodeMain,
		PageNodeSub,
		Profile,
		Card,
		Activity,
		SearchLite,
		BlogAccount,
		BlogCategory,
		BlogCalendar,
		BlogTag,
		Empty,
	},
	setup(props: any, { root }: any) {
		const { $message, $route } = root;
		const t = root.$t.bind(root);
		const getCategory = (): string => {
			if ($route.name === 'BlogArticleCategoryId') {
				return $route?.params?.onlyid || '';
			}
			return '';
		};
		const getDate = (): string => {
			//console.log('获取的日期参数', $route?.params?.date);
			if ($route.name === 'BlogArticleArchiveId') {
				return $route?.params?.date || '';
			}
			return '';
		};
		//监听目录
		const isShowMenu: Ref<boolean> = ref(false);
		const menu: Ref<Anchor[]> = ref([]);
		watch(
			() => store.state.isShowArticleMenu,
			value => {
				isShowMenu.value = value;
				value && (menu.value = pub.get('articleAnchorArr') || []);
				//console.log(pub.get('articleAnchorArr'), menu.value);
			},
			{ immediate: true }
		);
		const toAnchor = (anchorItem: Anchor) => {
			const { onlyid, tagName } = anchorItem;
			const targetElement = document.querySelector(`${tagName}[name="${onlyid}"]`);
			if (targetElement) {
				let { y } = targetElement?.getBoundingClientRect() || {};
				y += document.documentElement.scrollTop;
				if (typeof y !== 'number' || isNaN(y)) {
					y = (() => {
						let y = (targetElement as HTMLElement).offsetTop;
						y += 330;
						return y;
					})();
				}
				try {
					document.documentElement.scrollTo(0, y - 60);
				} catch (e) {
					document.documentElement.scrollTop = y - 60;
				}
			} else {
				$message.error('无法找到锚点');
			}
		};
		return { getCategory, getDate, t, isShowMenu, menu, toAnchor };
	},
});
